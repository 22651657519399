import { useEffect, useState } from 'react'
import firebase from './utils/firebase'
import { QueryClientProvider } from 'react-query'
import { SignedInView } from './views/SignedIn'
import { SignedOutView } from './views/SignedOut'
import { queryClient } from './utils/query'
import { Layout } from './components'
import './index.css'

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AppBase />
    </QueryClientProvider>
  )
}

const AppBase = () => {
  const [loggedIn, setLoggedIn] = useState(firebase.auth().currentUser)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      queryClient.setQueryData('uid', user?.uid)
      setLoggedIn(!!user)
    })
    setTimeout(() => setLoading(false), 800)
  }, [])

  if (loading) return <Layout />

  return loggedIn ? <SignedInView /> : <SignedOutView />
}

export default App
