import { Layout } from '../../components'
import { SocialAccounts } from './SocialAccounts'
import { ArenaChannels } from './ArenaChannels'
import { LastSync } from './LastSync'
import { SyncOptions } from './SyncOptions'

export const SignedInView = () => {
  return (
    <Layout>
      <SocialAccounts />

      <ArenaChannels />

      <SyncOptions />

      <LastSync />
    </Layout>
  )
}
