import { formatDuration, intervalToDuration } from 'date-fns'
import { nextCronOccurrence } from 'cron-fns'

export const getDuration = (end) =>
  formatDuration(intervalToDuration({ start: new Date(), end }))

export const getNextSyncTime = (frequency) => {
  let now = new Date()
  let date
  if (frequency === 'hourly') {
    date = nextCronOccurrence('0 * * * * *', now)
  } else if (frequency === 'daily_4x') {
    date = nextCronOccurrence('0 0,6,12,18 * * *', now)
  } else if (frequency === 'daily_2x') {
    date = nextCronOccurrence('0 0,12 * * *', now)
  } else if (frequency === 'daily') {
    date = nextCronOccurrence('0 12 * * * *', now)
  } else if (frequency === 'weekly') {
    date = nextCronOccurrence('0 12 * * WED', now)
  } else {
    return ''
  }
  return getDuration(date)
}

export const getNextBlocks = async (user) => {
  const { arenaChannels: channels = [], lastSync } = user
  const channelBlocks = await Promise.all(
    channels.map(({ id, lastBlock }) =>
      getRecentBlocksForChannel(id, lastSync, lastBlock),
    ),
  )

  return channelBlocks
    .map((block) => block.slice(0, user.postLimit || 1))
    .flat()
}

const getRecentBlocksForChannel = async (channelId, lastSync, blockId) => {
  const channel = await fetch(
    `https://api.are.na/v2/channels/${channelId}/contents?direction=desc&per=100`,
  )
  const channelJson = await channel.json()

  if (!channelJson.contents) return []

  let blocks = channelJson?.contents

  if (blockId) {
    const index = blocks.findIndex((b) => b.id === blockId)
    blocks = blocks.slice(0, index)
  } else {
    blocks = blocks.filter((b) => new Date(b.created_at) > lastSync)
  }

  blocks = blocks.filter((b) => b.image).reverse()

  return blocks.map((b) => ({
    channelId,
    id: b.id,
    url: b.image.large.url,
    type: b.image.content_type.split('/')[1],
  }))
}

export const getArenaChannelStartingBlock = async (channelId, blockId) => {
  if (!blockId) return window.alert('Please enter a valid block id')
  if (blockId.match(/^http/)) {
    const path = blockId.split('?')[0]
    const parts = path.split('/')
    blockId = parts[parts.length - 1]
  }
  const channel = await fetch(
    `https://api.are.na/v2/channels/${channelId}/contents?direction=desc&per=100`,
  )
  const block = await fetch(`https://api.are.na/v2/blocks/${blockId}`)
  if (block.status !== 200) return window.alert('Please enter a valid block id')

  const json = await channel.json()
  const index = json.contents.findIndex((b) => b.id === +blockId)
  if (json.contents[index + 1]) {
    blockId = json.contents[index + 1].id
  }

  return blockId
}
