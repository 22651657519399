import { useSetUser, useUser } from '../../utils/query'
import { CloseIcon, TwitterIcon } from '../../components'

const ENDPOINT = process.env.REACT_APP_ENDPOINT

export const SocialAccounts = () => {
  return (
    <div className="w-full mt-14">
      <Twitter />
    </div>
  )
}

const Twitter = () => {
  const { data: user } = useUser()
  const setUser = useSetUser()

  const onConnect = () =>
    user && window.open(`${ENDPOINT}/twitterLogin?uid=${user.uid}`, '_self')

  const onDisconnect = () => {
    if (
      window.confirm(
        'Are you sure you want to disconnect your Twitter account?',
      )
    )
      setUser.mutateAsync({
        twitterAccessToken: null,
        twitterAccessSecret: null,
        twitterUsername: null,
      })
  }
  const isConnected = user?.twitterAccessToken
  const twitterName = user?.twitterUsername

  return (
    <div
      className={`flex-1 flex items-center justify-between border-2 border-gray-1 rounded-md px-4 py-2 ${
        !isConnected ? 'cursor-pointer' : ''
      }`}
      onClick={!isConnected ? onConnect : null}
    >
      <div className="flex items-center w-full">
        <TwitterIcon className="w-4 mr-2" />

        <div className="flex flex-col">
          {isConnected ? (
            <>
              <p
                className="text-xs text-caps font-bold my-1"
                style={{ fontSize: 8, color: '#5D5FEF', lineHeight: '8px' }}
              >
                CONNECTED
              </p>
              {twitterName && (
                <p className="text-xs font-medium">@{twitterName}</p>
              )}
            </>
          ) : (
            <p className="text-xs font-medium my-2">Connect Twitter</p>
          )}
        </div>
      </div>

      {isConnected && (
        <div className="cursor-pointer" onClick={onDisconnect}>
          <CloseIcon />
        </div>
      )}
    </div>
  )
}
