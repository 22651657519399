import firebase from '../utils/firebase'
import { queryClient } from '../utils/query'
import { LogoSVG } from './'

export const Layout = ({
  children,
  maxWidth = 'max-w-md',
  showLogo = true,
}) => {
  const onSignOut = () => {
    firebase.auth().signOut()
    queryClient.setQueryData('user', null)
  }

  return (
    <div className="flex flex-col pb-20">
      <div className={`flex flex-col ${maxWidth} w-full p-6`}>
        {showLogo && <LogoSVG />}
        {children}
      </div>

      {firebase.auth().currentUser && (
        <div className="fixed bottom-0 w-full py-6 px-4 bg-white z-50">
          <a href="/#" className="text-xs underline" onClick={onSignOut}>
            Sign out
          </a>
        </div>
      )}
    </div>
  )
}
