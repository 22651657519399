import { useRef, useState } from 'react'
import { Menu, Item, useContextMenu } from 'react-contexify'
import { AddIcon, MenuIcon } from '../../components'
import { useSetUser, useUser } from '../../utils/query'
import { getArenaChannelStartingBlock } from '../../utils/arena'
import arenaLogo from '../../assets/arena.png'
import 'react-contexify/dist/ReactContexify.css'

const buttonClasses = 'mb-3 border-2 border-dark px-3 py-3'

export const ArenaChannels = () => {
  const { data: user } = useUser()

  return (
    <div className="w-full my-16">
      {(user?.arenaChannels || []).map((channel) => (
        <ArenaChannelItem key={channel.id} {...channel} />
      ))}

      <AddArenaChannelForm />
    </div>
  )
}

const ArenaChannelItem = ({ title, usernameSlug, username, id }) => {
  const { data: user } = useUser()
  const setUser = useSetUser()

  let label = `are.na / ${id}`
  if (title && username) {
    label = `are.na / ${username} / ${title}`
  }

  const onChangeStartItem = (id) => async () => {
    let blockId = window.prompt(
      'Enter the URL or ID of the block you want to start publishing from',
    )

    blockId = await getArenaChannelStartingBlock(id, blockId)

    await setUser.mutateAsync({
      arenaChannels:
        user.arenaChannels?.map((c) =>
          c.id === id ? { ...c, lastBlock: +blockId } : c,
        ) || [],
    })
  }

  const onDeleteChannel = (id) => async () => {
    const shouldDelete = window.confirm(
      'Are you sure you want to unlink this Are.na channel?',
    )
    if (!shouldDelete) return
    await setUser.mutateAsync({
      arenaChannels: user.arenaChannels?.filter((c) => c.id !== id),
    })
  }

  const { show } = useContextMenu({ id: id || null })
  const options = {
    'Start publishing from...': onChangeStartItem(id),
    'Delete Channel': onDeleteChannel(id),
  }
  const slug = usernameSlug || slugify(username)
  const channelUri = `https://are.na/${slug}/${id}`

  return (
    <div
      className={`${buttonClasses} ${
        !user?.twitterAccessToken ? 'opacity-50' : ''
      } flex-1 flex items-center justify-between`}
    >
      <div className="flex items-center w-full">
        <img alt="logo" src={arenaLogo} className="w-7 mr-4" />
        <a target="_blank" rel="noreferrer" href={channelUri}>
          <p className="w-full text-left text-xs">{label}</p>
        </a>
      </div>

      <div
        className="w-5 h-5 cursor-pointer flex items-center justify-center"
        onClick={show}
      >
        <MenuIcon />
      </div>

      <Menu id={id}>
        {Object.entries(options || {}).map(([key, value]) => (
          <Item key={key} onClick={value}>
            {key}
          </Item>
        ))}
      </Menu>
    </div>
  )
}

const AddArenaChannelForm = () => {
  const [adding, setAdding] = useState(false)
  const [newChannelText, setNewChannelText] = useState('')
  const { data: user } = useUser()
  const setUser = useSetUser()
  const inputRef = useRef(null)

  const onAddChannel = () => {
    if (!user?.twitterAccessToken)
      return alert(
        'You must connect a social account before adding a new are.na channel',
      )
    setAdding(true)
    setTimeout(() => {
      inputRef.current.focus()
    }, 10)
  }

  const onCancelChannel = () => {
    setAdding(false)
    setNewChannelText('')
  }

  const onSubmitChannel = async () => {
    let channelId = newChannelText

    if (newChannelText.match(/^http/)) {
      const path = newChannelText.split('?')[0]
      const parts = path.split('/')
      channelId = parts[parts.length - 1]
    }

    const metadata = await fetchArenaChannelMetadata(channelId)

    let shouldCreate = true

    if (!metadata) {
      shouldCreate = false
      alert('Channel does not exist')
    }

    const existing = user.arenaChannels || []
    if (existing.some((c) => c.id === metadata.id)) {
      shouldCreate = false
      alert('You already added this channel')
    }

    if (shouldCreate) {
      await setUser.mutateAsync({
        arenaChannels: [...existing, metadata],
      })
    }

    setAdding(false)
    setNewChannelText('')
  }

  return (
    <>
      {adding ? (
        <form
          onSubmit={(e) => {
            e.preventDefault()
            onSubmitChannel()
          }}
        >
          <div
            className={`${buttonClasses} flex-1 flex items-center justify-between mb-4`}
          >
            <div className="flex items-center w-full">
              <img alt="logo" src={arenaLogo} className="w-8 mr-2" />
              <input
                ref={inputRef}
                className="border-0 flex-1 p-2 mr-4 text-xs"
                value={newChannelText}
                placeholder="Enter arena channel url"
                onChange={(e) => setNewChannelText(e.target.value)}
              />
              <div className="cursor-pointer mr-1" onClick={onSubmitChannel}>
                <AddIcon />
              </div>
            </div>
          </div>
        </form>
      ) : null}

      <div
        className={`${
          !user?.twitterAccessToken ? 'opacity-50 pointer-events-none' : ''
        } `}
      >
        <a
          className="w-full text-xs font-medium text-blue underline"
          href="/#"
          onClick={adding ? onCancelChannel : onAddChannel}
        >
          {adding ? 'Cancel' : 'Add a channel +'}
        </a>
      </div>
    </>
  )
}

const fetchArenaChannelMetadata = async (channelId) => {
  try {
    const response = await fetch(`https://api.are.na/v2/channels/${channelId}`)
    const body = await response.json()
    return {
      id: body.slug,
      usernameSlug: body.owner.slug,
      username: body.owner.username,
      title: body.title,
    }
  } catch (e) {
    return null
  }
}

const slugify = (str) => {
  str = str.replace(/^\s+|\s+$/g, '')
  str = str.toLowerCase()
  const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
  const to = 'aaaaeeeeiiiioooouuuunc------'
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
  }
  str = str
    .replace(/[^a-z0-9 -]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-')

  return str
}
