import { Select } from '../../components'
import { useSetUser, useUser } from '../../utils/query'

export const SyncOptions = () => {
  const { data: user } = useUser()
  const setUser = useSetUser()

  const onSetPostFrequency = (e) =>
    setUser.mutate({
      postFrequency: e.target.value,
      timezoneOffset: getTimezoneOffset(),
    })

  const onSetPostLimit = (e) =>
    setUser.mutate({
      postLimit: +e.target.value,
      timezoneOffset: getTimezoneOffset(),
    })

  return (
    <div
      className={`flex w-full space-x-4 mb-14 ${
        !user?.twitterAccessToken ? 'opacity-50 pointer-events-none' : ''
      }`}
    >
      <Select
        value={user?.postFrequency}
        onChange={onSetPostFrequency}
        label="Posting Frequency"
        options={POST_FREQUENCY_OPTIONS}
      />
      <Select
        value={user?.postLimit}
        onChange={onSetPostLimit}
        label="Block Limit per Channel"
        options={POST_LIMIT_OPTIONS}
      />
    </div>
  )
}

const getTimezoneOffset = () => Math.floor(new Date().getTimezoneOffset() / 60)

const POST_FREQUENCY_OPTIONS = {
  hourly: 'Every Hour',
  daily_4x: 'Every 6 Hours',
  daily_2x: 'Every 12 Hours',
  daily: 'Every Day at Noon',
  weekly: 'Every Wednesday at Noon',
  never: 'Never',
}

const POST_LIMIT_OPTIONS = {
  1: 'Up to 1 Block',
  2: 'Up to 2 Blocks',
  3: 'Up to 3 Blocks',
  4: 'Up to 4 Blocks',
  5: 'Up to 5 Blocks',
}
