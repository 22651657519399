import { useEffect, useState } from 'react'
import { useSyncUser, useUser } from '../../utils/query'
import { getDuration, getNextBlocks, getNextSyncTime } from '../../utils/arena'

export const LastSync = () => {
  const { data: user } = useUser()
  const [blocks, setBlocks] = useState([])
  const freq = user?.postFrequency || 'never'
  const [nextSyncTime, setNextSyncTime] = useState(getNextSyncTime(freq))
  const syncUser = useSyncUser()
  const onSync = () => syncUser.mutateAsync()
  const canSync = user?.twitterAccessToken && user?.arenaChannels?.length > 0
  const lastSyncPostCount = user?.lastSyncPostCount || 0

  useEffect(() => {
    const interval = setInterval(
      () => setNextSyncTime(getNextSyncTime(freq)),
      1000,
    )
    return () => clearInterval(interval)
  }, [freq])

  useEffect(() => {
    if (user) getNextBlocks(user).then(setBlocks)
  }, [user])

  return (
    <>
      {user?.lastSync && (
        <p onClick={false && canSync ? onSync : null} className="text-xs">
          Last Post: {lastSyncPostCount} blocks, {getDuration(user.lastSync)}{' '}
          ago
        </p>
      )}

      {canSync && (
        <>
          <p
            className="text-xs mt-2"
            onClick={false && canSync ? onSync : null}
          >
            Next Post:{' '}
            {freq === 'never' || !nextSyncTime
              ? 'Never'
              : blocks.length === 0
              ? 'No new blocks to post'
              : `${blocks.length} block${
                  blocks.length === 1 ? '' : 's'
                }, ${nextSyncTime} from now`}
          </p>

          {freq !== 'never' && (
            <ArenaBlocks
              blocks={blocks.map((b) => ({
                ...b,
                title: user.arenaChannels.find((c) => c.id === b.channelId)
                  ?.title,
              }))}
            />
          )}
        </>
      )}
    </>
  )
}

const ArenaBlocks = ({ blocks }) => (
  <div className="mt-4 flex flex-wrap">
    {blocks.map(({ id, url, title }) => (
      <div key={`block-${id}`} className="mx-1 my-1">
        <a
          target="_blank"
          rel="noreferrer"
          href={`https://www.are.na/block/${id}`}
        >
          <img
            className="w-20 h-20 object-cover"
            alt={`block-${id}`}
            src={url}
          />
          <p className="mt-1 text-2xs text-secondary text-center">/{title}</p>
        </a>
      </div>
    ))}
  </div>
)
