import { useQuery, QueryClient, useMutation } from 'react-query'
import firebase from './firebase'

export const queryClient = new QueryClient()

export const useUserId = () => useQuery('uid', { enabled: false })

export const useUser = () => {
  const { data: uid } = useUserId()
  return useQuery('user', { enabled: !!uid, queryFn: () => getUser(uid) })
}

export const useSetUser = () => {
  const { data: uid } = useUserId()
  return useMutation((update) => setUser(uid, update), {
    onSuccess: () => queryClient.refetchQueries('user'),
  })
}

export const useSyncUser = () => {
  const { data: uid } = useUserId()
  return useMutation(
    () => window.fetch(`${process.env.REACT_APP_ENDPOINT}/syncUser?uid=${uid}`),
    {
      onSuccess: () => queryClient.refetchQueries('user'),
    },
  )
}

const getUser = async (uid) => {
  const doc = await firebase.firestore().collection('users').doc(uid).get()
  const authUser = firebase.auth().currentUser
  return {
    email: authUser.email,
    name: authUser.displayName,
    uid: authUser.uid,
    ...(doc.exists ? doc.data() : {}),
  }
}

const setUser = async (uid, update = {}) => {
  await firebase
    .firestore()
    .collection('users')
    .doc(uid)
    .set(update, { merge: true })
}
