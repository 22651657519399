import firebase from '../utils/firebase'
import { Layout, GoogleIcon, TwitterIcon, HeroSVG } from '../components'
import { useEffect, useState } from 'react'

var actionCodeSettings = {
  url: 'https://arenapublisher.com/',
  handleCodeInApp: true,
}

const googleProvider = new firebase.auth.GoogleAuthProvider()
const twitterProvider = new firebase.auth.TwitterAuthProvider()

export const SignedOutView = () => {
  return (
    <Layout maxWidth="max-w-sm" showLogo={false}>
      <HeroSVG />

      <h2 className="mt-8 font-semibold">Are.na as a publishing tool</h2>
      <p className="mt-4 mr-4">
        Publish blocks from Are.na automatically to Twitter
      </p>
      <p className="mt-2 mb-8">Questions? hello@56.digital</p>

      <Auth />
    </Layout>
  )
}

const Auth = () => {
  const [email, setEmail] = useState('')
  const isMobile = 'ontouchstart' in document.documentElement
  const onSignInGoogle = () =>
    isMobile
      ? firebase.auth().signInWithRedirect(googleProvider)
      : firebase.auth().signInWithPopup(googleProvider)
  const onSignInTwitter = () =>
    isMobile
      ? firebase.auth().signInWithRedirect(twitterProvider)
      : firebase.auth().signInWithPopup(twitterProvider)
  const onSignInEmail = () => {
    firebase
      .auth()
      .sendSignInLinkToEmail(email, actionCodeSettings)
      .then(() => {
        window.localStorage.setItem('emailForSignIn', email)
        setEmail('')
        alert(
          "We'll send you an email with a link to sign in. This may take a few minutes.",
        )
      })
      .catch(() => alert('Something went wrong'))
  }

  useEffect(() => {
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      var email = window.localStorage.getItem('emailForSignIn')
      if (!email) {
        email = window.prompt('Please provide your email for confirmation')
      }
      firebase
        .auth()
        .signInWithEmailLink(email, window.location.href)
        .then((result) => {
          window.history.pushState({}, document.title, window.location.pathname)
          window.localStorage.removeItem('emailForSignIn')
        })
        .catch((e) => {})
    }
  }, [])

  return (
    <div className="flex flex-col">
      <div className="flex flex-1 border-2 border-gray-2 w-full px-3 py-2">
        <form
          className="flex-1"
          onSubmit={(e) => {
            e.preventDefault()
            onSignInEmail()
          }}
        >
          <input
            placeholder="Your email"
            autoComplete="email"
            className="w-full px-1 py-1"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </form>

        <button
          className="text-xs font-medium text-black ml-2"
          disabled={!email}
          onClick={onSignInEmail}
        >
          Sign in with magic link
        </button>
      </div>

      <button
        className="w-full mt-3 border-2 border-gray-1 font-medium text-xs py-3 rounded-md"
        onClick={onSignInTwitter}
      >
        <div className="flex justify-center items-center">
          <TwitterIcon className="mr-1" /> Sign in with Twitter
        </div>
      </button>

      <button
        className="w-full mt-3 border-2 border-gray-1 font-medium text-xs py-3 rounded-md"
        onClick={onSignInGoogle}
      >
        <div className="flex justify-center items-center">
          <GoogleIcon className="mr-1" /> Sign in with Google
        </div>
      </button>
    </div>
  )
}
