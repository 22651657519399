export const Select = ({ key, label, options, value, onChange }) => {
  return (
    <div className="flex flex-col flex-1">
      <label className="mb-2 text-xs">{label}</label>
      <div className="flex-1 flex items-center justify-between p-3 border-2 border-gray-1 rounded-md">
        <select
          className="w-full text-sm text-gray-3 pl-1"
          onChange={onChange}
          value={value}
          name={key}
          id={key}
        >
          {Object.entries(options).map(([key, value]) => (
            <option key={key} value={key}>
              {value}
            </option>
          ))}
        </select>
        <svg
          width="12"
          height="7"
          viewBox="0 0 12 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0.980858L0.968385 0.0124738L5.63767 4.68176L10.3194 0L11.307 0.987567L5.65685 6.63771L0 0.980858Z"
            fill="#111111"
          />
        </svg>
      </div>
    </div>
  )
}
